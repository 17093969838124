// import {createTheme} from '@material-ui/core'
const size = {
  smp: '450px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

export const theme =  {
  colors: {
    body: '#E5E5E5',
    text: '#00174D',
    heading3: '#2D3E6A',
    headingLight: '#fff',
    darkText: "#828282",
    bubble: "#EEF6FD",
    urgent: "#EB5757"
  },
  breaks: {
    up: bp => `(min-width: ${size[bp]})`,
    down: bp => `(max-width: ${size[bp]})`,
    between: (bp1, bp2) => `(min-width: ${size[bp1]}) and (max-width: ${size[bp2]})`,
    only: bp => `(width: ${size[bp]})`
  },
  palette: {
    primary: {
      main: 'rgb(50, 48, 49)',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0ED978',
      contrastText: '#fff'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      smp: 450,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  mixins: {
    toolbar: {
      minHeight: '6rem'
    }
  },
  typography: {
  },
  maxWidth: '1280px',
  maxWidthMd: '960px'
}

// console.log(theme);