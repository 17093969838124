/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "styled-components";
// import { ThemeProvider as MuiThemeProvider, 
//   CssBaseline} from '@material-ui/core';
import { theme } from "../utils/theme"

import gsap from 'gsap';

import HeroBg from '../images/heroBg-copy.svg';


import favicon from '../images/fav-logo/favicon.ico';
import appleFav from '../images/fav-logo/apple-touch-icon.png';
import fav32 from '../images/fav-logo/favicon-32x32.png';
import fav16 from '../images/fav-logo/favicon-16x16.png';

import Header from "./header"
import Footer from './footer';
import NavItems from "./mobile-nav/NavItems";
import "../css/main.scss"



const Layout = ({ children , color, pageTitle, location}) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

const schema = {
  "@context": "https://schema.org",
  "@type": "Corporation",
  "name": "Novosoft",
  "alternateName": "Novosoft Solutions",
  "url": "https://www.novosoftcode.com",
  "description": data.site.siteMetadata.description,
  // "logo": "https://d-wdesign.com/static/logo-4d8d3da1811ecc735168581dd1114e25.png",
  // "sameAs": [
  //   "https://www.instagram.com/celebrand/",
  //   "https://www.facebook.com/celebrand.us",
  //   "https://www.linkedin.com/company/celebrand"
  //   ]
  }

  const [checked, setChecked] = useState(false);

    const handleCheck = () => {
        setChecked(prev => !prev);
    } 

    useEffect(() => {
        const tl = gsap.timeline()

        if (checked) {
          tl.addLabel("open-nav")
          .to('.nav_bg', {scale: 150, background: `radial-gradient(#2c4fd0, #1a81e4 20%)`, duration: .4}, 'open-nav')
          .to('.nav', {autoAlpha: 1, width: '100%', duration: .2}, 'open-nav')
        } else {

          document.getElementById('nav_toggle').checked = false;

          tl.addLabel("close-nav")
          .to('.nav_bg', {scale: 1, duration: .4}, 'close-nav')
          .to('.nav', {autoAlpha: 0, width: 0, duration: .2}, 'close-nav')
        }
    }, [checked])

  return (
    // <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
    {/* <CssBaseline /> */}
      <div>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}
      title={pageTitle || data.site.siteMetadata.title}
      >

        <meta property="og:title" content={pageTitle ? pageTitle : data.site.siteMetadata.title} />
        
        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleFav} />
        <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
        <link rel="icon" type="image/png" sizes="16x16" href={fav16}/>
        
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta name="description" content={data.site.siteMetadata.description} />

        <title>{pageTitle || data.site.siteMetadata.title}</title>

        {schema && <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>}
      </Helmet>

      <div className="nav_bg"></div>

        <nav className="nav">
          <NavItems active="1" click={handleCheck} />
        </nav>

        <Header click={handleCheck} location={location} />
        <main className="main-section">{children}</main>
        <Footer />


      </div>
      </ThemeProvider>
    // </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
