import React, { useEffect, useState } from "react"
import { Link } from "gatsby";
import RowWrapper from '../utils/rowWrapper';
import styled from 'styled-components';

import Logo from '../images/novo-logo-web.png'

import Nav from './nav';

import MobileNav from './mobile-nav/Nav';


const Container = styled.header`
  height: 8rem;
  position: fixed;
  background-color: #fff;
  padding-top: 1.5rem;
  width: 100%;
  z-index: 100;
  box-shadow: 0 4px 10px #00000022;
`
const Content = styled(RowWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LogoContainer = styled.div`
  width: 20rem;

  img {
    width: 100%;
  }
`

const NavContainer = styled.div`

@media ${props => props.theme.breaks.down('md')} {
    padding-right: 1.5rem;
  }

`

let prevScroll = 0;


const Header = ({ siteTitle, click, location }) => {

  // const [sticky, setSticky] = useState(false);

  const nav = ["/", "/contact-us", "/success"]


  // useEffect(() => {

  //   const listener = () => {
  //     if (!nav.includes(location)) return
  //     const currScroll = window.scrollY;
  //     prevScroll = window.scrollY;

  //     if ( currScroll > 50)
  //       setSticky(true)
  //     else
  //       setSticky(false)
  //   }

  //   window.addEventListener('scroll', listener);
  //   return () => {
  //     window.removeEventListener('scroll', listener);
  //   };
  // }, [])

  return (
    <Container 
    // sticky={sticky}
    >
     <Content>
       <LogoContainer>
        <Link to="/">
          <img src={Logo} alt="Novosoft Logo" />
        </Link>
       </LogoContainer>
      
       <NavContainer>
        <Nav location={location} />
        <MobileNav click={click} />
       </NavContainer>

     </Content>
    </Container>
) }

export default Header
