import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby'

// import {Grid} from '@material-ui/core';


const List = styled.ul`
  list-style: none;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  
  a {
      text-decoration: none;
      color: ${props => props.theme.colors.headingLight};
      /* font-family: 'Arsenal'; */
      opacity: .8;
      font-size: 2rem;
      letter-spacing: 1px;
      /* text-transform: uppercase; */
      filter: drop-shadow(4px 10px 5px #00000044);
      transition: all .3s;
      display: block;

      &:hover {
        opacity: 1;
        filter: drop-shadow(4px 15px10px #00000033);
        transform: scale(1.03);
      }

    @media ${props => props.theme.breaks.up('sm')} {
      font-size: 3rem;
    }

  }

`

const ListItem = styled.li`
  cursor: pointer;
  display: block;
  margin-bottom: 4.5rem;
  /* visibility: hidden; */
  text-align: center;

  a:hover *, a:active *, a.active * {
    color: ${props => props.theme.colors.headingLight};
  }

  @media ${props => props.theme.breaks.up('sm')} {
    margin-bottom: 5.5rem;
  }
`

const MobileNav = styled.div`
  padding-top: 12rem;
`

const Hr = styled.hr`
  border: 1px solid ${props => props.theme.colors.headingLight};
  height: 1px;
  background-color: ${props => props.theme.colors.headingLight};
`

const SubMenu = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 4rem auto 0 auto;
`

const SubItems = styled.li`
  display: block;
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;

  a {
    font: inherit;
    color: ${props => props.theme.colors.headingLight};
  }

  &.nav-copyright {
    font-size: 1.3rem;
    color: ${props => props.theme.colors.headingLight};
  }
`


 
export default ({ active, click }) => {

  const titles = [
    {
    title: 'Home',
    href: '/'
    },
    {
    title: 'About Us',
    href: '/',
    tag: 'about'
    },
    {
    title: 'Services',
    href: '/',
    tag: 'services'
    },
    {
    title: 'Technologies',
    href: '/',
    tag: 'tech'
    },
    {
    title: 'Contact Us',
    href: '/contact-us'
    }
  ]

  const isActive = ({ location, href }) => {
    return `${location.pathname}${location.hash}` === href ? { className: "active" } : {}
  }
 
 return (
  <List className="nav_list">
    
    <MobileNav>
      {titles.map(({title, href, tag}) => 
      <ListItem key={title}><Link to={href} state={tag ? {tag} : {}} onMouseDown={click}>{title}</Link></ListItem>
      )}
      {/* <Hr />

      <SubMenu>
        <SubItems><a href="https://www.facebook.com/grandviewkleindiamonds/">Linked In</a></SubItems>
        <SubItems><a href="https://www.instagram.com/grandviewkleindiamonds/">Instagram</a></SubItems>
        <SubItems className="nav-copyright">COPYRIGHT © Novosoft Inc, 
            ALL RIGHTS RESERVED. </SubItems>
      </SubMenu> */}
    </MobileNav>
  </List>
 )
}