import React from 'react';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { Link } from 'gatsby'
import gsap from 'gsap';

import RowWrapper from '../utils/rowWrapper';
import footerSvg from '../images/footer.svg'
import footerClipping from '../images/bottom-cover.png'
import Logo from '../images/novo-logo-white.png'

import LinkedInIcon from '../images/LinkedInIcon';
import FacebookIcon from '../images/facebook';


const Container = styled.footer`
  min-height: 50rem;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: bottom;
  margin-top: 5rem;
  position: relative;
  padding-top: 18rem;
  font-family: "Montserrat";

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
`

const Clipping = styled.div`
  position: absolute;
  width: 100%;
  height: 15rem;
  top: -2px;
  background-image: url(${p => p.bg});
  background-size: cover;
  background-position: bottom;

  @media ${props => props.theme.breaks.down('md')} {
    top: -3px;
  }
`

const LogoImage = styled.div`
  margin-top: 1rem;
  max-width: 22rem;

  img {
    width: 100%;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    margin: 0 auto;
    margin-bottom: 3rem;
    /* max-width: none; */
    width: 50%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    max-width: none;
  }
`

const Links = styled.ul`
  width: max-content;
  margin: 0 auto;
  @media ${props => props.theme.breaks.down('sm')} {
    padding: 0 1rem;
  }
`

const Item = styled.li`
  color: ${props => props.theme.colors.headingLight};
  font-family: "Montserrat";
  font-size: 1.6rem;
  font-weight: 400;
  margin: 2rem 0;
  cursor: pointer;

  span {
    display: block;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: inherit;
  }

  * {
    color: ${props => props.theme.colors.headingLight};
    font-family: inherit;
  }
`

const Social = styled.div`


  h6 {
    font-family: "Montserrat";
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    margin: 10rem 0 1rem 0;
    color: ${props => props.theme.colors.headingLight};

    @media ${props => props.theme.breaks.down('lg')} {
      margin: 1rem 0;
    }
  }

  svg {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.5rem;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    width: 40%;
    margin: 2rem auto 8rem auto;
  }

  @media ${props => props.theme.breaks.down('smp')} {
    width: 80%;
  }

`

const SocialIcons = styled.div`
  text-align: center;

`

const Copyright = styled.div`
  position: absolute;
  bottom: 2rem;
  text-align: center;
  width: 100%;

  span {
    display: block;
    text-align: center;
    color: ${props => props.theme.colors.headingLight};
    font-family: "Montserrat";
    font-size: 1.8rem;
    font-weight: 400;
  }
`


const Footer = () => {

  const scrollTo = (id) => {
    gsap.to(window, {scrollTo: {y: `#${id}`, offsetY: 180}, duration: 2.5, ease: "ease.inOut"})
  }

  const navItems = [
    {name: "Home", tag: ""},
    {name: "About Us", tag: "about"},
    {name: "Services", tag: "services"},
    {name: "Technologies", tag: "tech"},
  ]

  return (
    <Container bg={footerSvg}>

      <RowWrapper>
        <Grid container justifyContent="center" spacing={2} className="footer-content">

          <Grid item xs={12} lg={3}>
            <LogoImage>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </LogoImage>
          </Grid>
          <Grid item xs={5} sm={4} lg={3} >
            <Links>
            {navItems.map(({name, tag}) => <Item key={name} onClick={() => scrollTo(tag ? tag : "home")}>{name}</Item>)}
              <Item><Link to="/contact-us">Contact Us</Link></Item>
            </Links>
          </Grid>
          <Grid item xs={7} sm={4} lg={3}>
            <Links>
              <Item><span>Phone:</span> <a href="tel:845-806-0219">845.806.0219</a></Item>
              <Item><span>E-mail:</span> <a href="mailto:info@novosoftcode.com">info@novosoftcode.com</a></Item>
              
            </Links>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Social>
              <h6>Social Media Links:</h6>
              <SocialIcons>
                <a href="https://www.linkedin.com/in/novosoft"><LinkedInIcon /></a>
                <FacebookIcon />
              </SocialIcons>
            </Social>
          </Grid>

        </Grid>
      </RowWrapper>

      <Clipping bg={footerClipping}>
      </Clipping>

      <Copyright>
        <span>All Rights Reserved 2021</span>
      </Copyright>
    </Container>
  )
}

export default Footer;