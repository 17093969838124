import React, {useState, useEffect} from 'react';
import styled from 'styled-components';



const NavContainer = styled.div`
   position: relative;
   height: 18px;
   width: 15px;
    
    .nav_button {
        cursor: pointer;
        display: inline-block;
        height: 18px;
        width: 15px;
        text-align: center;
        position: absolute; 
        z-index: 1000;
    }
    
    #nav_toggle {
        display: none;
    }
    
    /* #nav_toggle:checked ~ .nav_bg {
        background-color: #fff;
        transform: scale(150);
    } */
    
    #nav_toggle:checked ~ .nav{
        opacity: 1;
        width: 100%;
    }

    #nav_toggle:checked + .nav_button .nav_icon#top-bar2{
        background-color: transparent !important;
    }
    
    #nav_toggle:checked + .nav_button .nav_icon#top-bar1 {
        top: 7px;
        transform: rotate(135deg);
        background-color: ${props => props.theme.colors.text};
    }
    
    #nav_toggle:checked + .nav_button .nav_icon#top-bar3{
        top: 7px;
        transform: rotate(-135deg);
        background-color: ${props => props.theme.colors.text};
    }
    
    .nav_icon {
        position: absolute;
        /* margin-top: 15px; */
        top: 0;
        left: 0;
    
        &
        /* &::before,
        &::after  */
        {
            display: inline-block;
            height: 2px;
            width: 30px;
            border-radius: 300px;
            background-color: ${props => props.theme.colors.text};
            transition: all .2s;
        }

        &#top-bar2 {
            top: 7px;
        }

        &#top-bar3 {
            top: 14px;
        }
        
    
        /* &::before, &::after {
            content: "";
            position: absolute;
            left: 0;
        }
    
        &::before { top: -7px; }
        &::after { top: 7px; } */
        
    }
    .nav_button:hover .nav_icon#top-bar1 {
        top: -2px;
    }
    .nav_button:hover .nav_icon#top-bar3 {
        top: 16px;
    }

    @media ${props => props.theme.breaks.up('md')} {
        display: none;
    }
    
`
 
export default ({ color, click}) => {

 
 return (
  <NavContainer color={color}>
     <input type="checkbox" onChange={click} id="nav_toggle" />
    <label htmlFor="nav_toggle" className="nav_button">
        <span className="nav_icon" id="top-bar1">&nbsp;</span>
        <span className="nav_icon" id="top-bar2">&nbsp;</span>
        <span className="nav_icon" id="top-bar3">&nbsp;</span>
    </label>
    
  </NavContainer>
 )
}