import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
    >
      <circle cx="64" cy="64" r="64" fill="#fff"></circle>
      <path
        fill="#1797d8"
        d="M44.119 95.934H29.184V47.93h14.935v48.004zm-7.463-54.563A8.648 8.648 0 0128 32.718a8.649 8.649 0 018.656-8.652c4.771 0 8.646 3.876 8.646 8.652.001 4.777-3.874 8.653-8.646 8.653zM100 95.934H85.081V72.59c0-5.566-.097-12.728-7.752-12.728-7.765 0-8.948 6.065-8.948 12.33v23.742H53.479V47.93H67.78v6.562h.204c1.99-3.774 6.857-7.753 14.117-7.753 15.105 0 17.897 9.939 17.897 22.868L100 95.934z"
      ></path>
    </svg>
  );
}

export default Icon;

