import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        d="M17.7 0C7.9 0 0 7.9 0 17.7c0 8.8 6.5 16.1 14.9 17.5V22.8h-4.5v-5.1h4.5v-3.9c0-4.4 2.6-6.9 6.7-6.9 1.9 0 4 .3 4 .3v4.4h-2.2c-2.2 0-2.9 1.4-2.9 2.8v3.3h4.9l-.8 5.1h-4.1v12.4c8.5-1.3 14.9-8.6 14.9-17.5C35.4 7.9 27.5 0 17.7 0z"
      ></path>
    </svg>
  );
}

export default Icon;