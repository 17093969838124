import React from 'react';
// import {Grid} from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'gatsby';
import gsap from 'gsap'

const NavContainer = styled.ul`
  display: flex;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const NavItem = styled.li`

  display: block;
  width: max-content;
  height: 3rem;
  line-height: 3rem;
  margin: 1rem 6rem 0 0;

  &, & * {
    text-decoration: none;
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;

    @media ${props => props.theme.breaks.down('lg')} {
      font-size: 1.6rem;
    }

  }

  &#contact-us {
    background-image: radial-gradient(#3F19BA, #02C5FF);
    padding: 2px;
    width: 15rem;
    height: auto;
    border-radius: 5px;

    span {
      text-align: center;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 5px;
    }
  }

  @media ${props => props.theme.breaks.down('lg')} {
    margin: 1rem 4rem 0 0;
  }
`

const ScrollItem = styled.span`
  cursor: pointer;
`

const Nav = ({ location }) => {

  const scrollTo = (id) => {
    gsap.to(window, {scrollTo: {y: `#${id}`, offsetY: 180}, duration: 1.6, ease: "ease.inOut"})
  }

  const navItems = [
    {name: "Home", tag: ""},
    {name: "About Us", tag: "about"},
    {name: "Services", tag: "services"},
    {name: "Technologies", tag: "tech"},
  ]

  return (
    <NavContainer>
      {navItems.map(({name, tag}) => {
        if (location === '/') {
          return <NavItem key={name} onClick={() => scrollTo(tag ? tag : "home")}><ScrollItem>{name}</ScrollItem></NavItem>
        } else {
          let state = tag ? {state: {tag: tag}} : {};
          return <NavItem key={name}><Link to="/" {...state}>{name}</Link></NavItem>
        }
      })}
      {/* <NavItem><Link to="/">Home</Link></NavItem>
      <NavItem><Link to="/" state={{ tag: "about" }}>About Us</Link></NavItem>
      <NavItem><Link to="/" state={{ tag: "services" }}>Services</Link></NavItem>
      <NavItem><Link to="/" state={{ tag: "tech" }}>Technologies</Link></NavItem> */}
      <NavItem id="contact-us"><Link to="/contact-us"><span>Contact Us</span></Link></NavItem>
      
    </NavContainer>
  )
}

export default Nav;